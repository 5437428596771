import {
    get,
    forOwn,
    has,
    isEmpty,
    pickBy,
    sortBy,
    includes,
    mapKeys,
    camelCase,
} from 'lodash-es'
import { isValid } from 'date-fns'
import { format } from 'date-fns'
import { CronExpressionParser } from 'cron-parser'
import { toString } from 'cronstrue'

/**
 * Parses tenant's dynamic settings and set them into the jsonforms schema
 * @param schema
 * @param tenant
 * @returns {*}
 */
export const getDynamicConfigSchema = (schema, dynamicConfigs) => {
    const definitions = get(schema, 'definitions', {})
    forOwn(definitions, (_, key) => {
        if (has(dynamicConfigs, key)) {
            // list of strings
            if ('enum' in schema.definitions[key]) {
                schema.definitions[key].enum = isEmpty(
                    get(dynamicConfigs, key, [])
                )
                    ? ['None']
                    : get(dynamicConfigs, key)
            }
            // list of objects, for multiselect component
            // We use options over enums because using enums invokes a custom validation called allowedValues.
            // Where list of objects in the schema are the 'allowedValues'. But since we save option.value and not the
            // object itself, the validation fails.
            else if (
                'items' in schema.definitions[key] &&
                'options' in schema.definitions[key]['items']
            ) {
                schema.definitions[key].items.options = get(
                    dynamicConfigs,
                    key,
                    ['none']
                )
            }
            // dynamic oneOf options are rendered outside of
            // JSONschema in a custom component, because some
            // longer list of options would overwhelm the schema validator
            else if ('oneOf' in schema.definitions[key]) {
                let type
                // Check if dynamicConfigs[key] is an array and has at least one element
                if (
                    Array.isArray(dynamicConfigs[key]) &&
                    dynamicConfigs[key].length > 0
                ) {
                    // Check if the first element of the array has a 'const' property
                    if ('const' in dynamicConfigs[key][0]) {
                        type = typeof dynamicConfigs[key][0].const
                    } else {
                        // Handle the case where 'const' property is missing
                        // default to type string and log error message
                        throw Error(
                            `Error: ${key} is missing const field, please check your most recent run context`
                        )
                    }
                } else {
                    // Handle the case where dynamicConfigs[key] is not an array or is empty
                    // default to type string and log error message
                    throw Error(
                        `Error: ${key} is not an array or is empty, please check your most recent run context`
                    )
                }
                schema.definitions[key] = {
                    type: type,
                    minLength: 1,
                    dynamicOneOfKey: key,
                }
            }
        }
    })
    return schema
}

/**
 * Checks if specified connector is connected based on tenant
 * @param tenant
 * @param connectorName
 * @returns {boolean}
 */
export const isConnectorConnected = (tenant, connectorName) => {
    return (
        tenant?.status?.auth[`${connectorName?.toUpperCase()}_AUTH_STATUS`] ===
        'Connected'
    )
}

/**
 * Capitalizes the first letter of a string
 * @param str
 * @returns {string}
 * @constructor
 */
export const Capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

/**
 *
 * @param str
 * @param len length of string before truncating and adding ellipses
 * @returns {*}
 */
export const truncate = (str, len) => {
    if (str && str.length > len) {
        return str.slice(0, len) + '...'
    } else {
        return str
    }
}

/**
 * https://github.com/mui-org/material-ui/issues/11517#issuecomment-407509327
 * Allows us to combine style objects. This also passes the theme if the object is a function
 * @param styles
 * @returns {function(*=): any}
 */
export const combineStyles = (...styles) => {
    return (theme) => {
        const outStyles = styles.map((arg) => {
            // Apply the "theme" object for style functions.
            if (typeof arg === 'function') {
                return arg(theme)
            }
            // Objects need no change.
            return arg
        })

        return outStyles.reduce((acc, val) => Object.assign(acc, val))
    }
}

/**
 * Sorts a resource by prop + order and returns a list of ids.....
 * @param data, prop, order
 * @returns list of resource's ids that were sorted by prop
 */
export const sortByProp = (data, prop, order, excludeProp, ids) => {
    if (!isEmpty(ids)) {
        data =
            !isEmpty(data) &&
            pickBy(data, (key) => {
                return includes(ids, key.id)
            })
    }
    const sortedData = sortBy(data, [
        (record) => {
            return get(record, prop, null) !== excludeProp
                ? isValid(Date.parse(get(record, prop, null)))
                    ? new Date(get(record, prop, null))
                    : typeof get(record, prop) === 'string'
                    ? get(record, prop).toLowerCase()
                    : get(record, prop, null)
                : null
        },
    ])

    if (order === 'ASC') {
        sortedData.reverse()
    }

    //Return the id's of the sorted list as the datagrid uses the 'ids' prop to determine order of data
    return Object.keys(sortedData).map((key) => {
        return sortedData[key]['id']
    })
}

/**
 * https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
 * flattens dictionaries keys to dot notation.....
 * @param data
 * @returns single layer dictionary
 */
export const flattenKeys = (obj, prefix, current) => {
    prefix = prefix || []
    current = current || {}

    // Remember kids, null is also an object!
    if (typeof obj === 'object' && obj !== null) {
        Object.keys(obj).forEach((key) => {
            flattenKeys(obj[key], prefix.concat(key), current)
        })
    } else {
        current[prefix.join('.')] = obj
    }

    return current
}

/**
 * trims prefix of namespace
 * @param namespace
 * @returns {*}
 */
export const trimEnvPrefix = (namespace) => {
    const staging = 'staging-'
    const prod = 'prod-'
    if (namespace.startsWith(staging)) {
        return namespace.slice(staging.length)
    } else if (namespace.startsWith(prod)) {
        return namespace.slice(prod.length)
    } else {
        return namespace
    }
}

/**
 * converts a nested object's keys from snake_case to camelCase
 * eg: tenant.integration.marketplace_settings -> tenant.integration.marketplaceSettings
 * @param namespace
 * @returns {*}
 */
export const convertNestedObjectToCamel = (obj) => {
    const newObj = mapKeys(obj, function (value, key) {
        return camelCase(key)
    })
    return newObj
}

/**
 * Retrieves the next synchronization time based on the provided schedule.
 *
 * @param {string} schedule - The cron expression schedule.
 * @param {boolean} paused - Indicates if the synchronization is paused.
 * @param {boolean} showTimezone - Indicates if the timezone should be displayed in the result.
 * @param {string} dateFormat - The desired format of the result date.
 * @returns {string} - The formatted next synchronization time.
 *
 * @throws {Error} - If there is an error parsing the cron expression.
 */
export const nextSyncTime = (schedule, paused, showTimezone, dateFormat) => {
    let nextCron
    try {
        if (paused) {
            return 'Paused'
        } else {
            CronExpressionParser.parse(schedule)
            toString(schedule, { throwExceptionOnParseError: true })

            nextCron = CronExpressionParser.parse(schedule, { tz: 'UTC' })
                .next()
                .toString()

            return formatDate(nextCron, true, dateFormat)
        }
    } catch (err) {
        console.error(`Failed to get nextSyncTime: ${err}`)
        return err.toString()
    }
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d)
}

export const formatDate = (
    dateString,
    showTimezone,
    dateFormat = 'M/d/yyyy, pp'
) => {
    const dateObj = new Date(dateString)
    let value

    if (!isValidDate(dateObj)) return dateString

    try {
        value = format(dateObj, dateFormat.toString())
    } catch (error) {
        value = format(dateObj, 'M/d/yyyy, pp')
    }

    if (showTimezone)
        value.concat(
            ' (',
            String(String(dateObj).split('(')[1]).split(')')[0],
            ')'
        )

    return value
}

export const trackUmamiEvent = (
    event_value,
    event_action,
    resource_id,
    resource_type,
    userID
) => {
    if (typeof window !== 'undefined' && window.umami) {
        const baseAdminURL = `https://${window.location.hostname.replace(
            /imp|emp/g,
            'admin'
        )}`
        const resourceURL = `${baseAdminURL}/${resource_type}/${resource_id}/show`
        window.umami.trackEvent(event_value, event_action, userID, resourceURL)
    }
}

export const defaultLogoSvg = (orientation, longName, id) => {
    const colorOptions = [
        '#cbc9c7',
        '#5cdfb9',
        '#48dacb',
        '#43b6e8',
        '#c652b4',
        '#101820',
    ]
    //pick a color based on the integration id, since that doesn't change.
    const color = colorOptions[id % 6]

    //establish the Letter that should be displayed in the logo.
    const initial = longName ? longName[0].toUpperCase() : ' '

    const width = orientation === 'LOGO (1X1)' ? '150' : '300'
    const textX = orientation === 'LOGO (1X1)' ? '75' : '150'

    const svgString = `<svg name="pandiumDefault" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 ${width} 150" >
            <g>
                <rect width="${width}" height="150" ry="10" rx="10" fill="${color}" />
                <text x="${textX}" y="100" text-anchor="middle" font-family="Arial" fill="white" font-size="70">${initial}  </text>
            </g>
        </svg>`

    return 'data:image/svg+xml;base64, ' + window.btoa(svgString)
}
